import { BigNumber } from "bignumber.js";

/**
 * Function that throws an error, either a provided Error object or a new Error with the given message.
 * @param error - The error message or Error object to throw.
 * @throws Always throws an error.
 */
export function throws(error: string | Error): never {
    if (error instanceof Error) {
        throw error; // Throw the provided Error object
    }
    throw new Error(error); // Throw a new Error with the provided error message
}

// Keys for local storage
const hashHistoryKey = "hashHistory";
const hashHistoryBrokenKey = "hashHistoryBroken";

// Interface for a hash record
interface HashRecord {
    hash: string;
    date: number;
    desc: string;
    status: string;
    show: boolean;
}

// Utility function: Sleep for a specified number of milliseconds
export const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(() => resolve(), ms));

/**
 * Function to save a hash record to local storage.
 * @param desc - Description of the hash record.
 * @param hash - Hash value to be saved.
 */
export function saveHashToLocalStorage(desc: string, hash: string) {
    const val = localStorage.getItem(hashHistoryKey);
    let ar;
    if (val !== null) {
        ar = parseJson(val);
    } else {
        ar = [];
    }
    ar.push({ hash: hash, date: +new Date(), desc: desc, status: "Processing", show: true });
    localStorage.setItem(hashHistoryKey, JSON.stringify(ar));
}

// Function to parse JSON while handling potential errors
function parseJson(value: string) {
    try {
        return JSON.parse(value); // Parse JSON string to object
    } catch (e: unknown) {
        console.error(e);
        localStorage.setItem(hashHistoryBrokenKey, value);
        localStorage.removeItem(hashHistoryKey);
        throw new Error("failed to parse hash history"); // Throw an error for failed parsing
    }
}

// Interface for a history transaction
export interface HistoryTransaction {
    hash: string;
    desc: string;
    date: number;
    status: string;
    show: boolean;
}

// Function to retrieve hash history from local storage
export function getHashHistory(): HistoryTransaction[] {
    const list = localStorage.getItem(hashHistoryKey);
    if (!list) {
        return [];
    } else {
        return parseJson(list);
    }
}

/**
 * Function to mark a hash as seen in history.
 * @param hash - Hash value to mark as seen.
 */
export function flagAsSeenHistoryHash(hash: string) {
    const value = localStorage.getItem(hashHistoryKey);
    if (!value) {
        return false;
    } else {
        const list = parseJson(value);
        const newList = list.map((rec: HashRecord) => {
            if (rec.hash === hash) {
                rec.show = false;
            }
            return rec;
        });
        localStorage.setItem(hashHistoryKey, JSON.stringify(newList));
    }
}

/**
 * Function to modify the status of a history hash.
 * @param hash - Hash value to modify status for.
 * @param status - New status to assign to the history hash.
 */
export function modifyHistoryHashStatus(hash: string, status: string) {
    const value = localStorage.getItem(hashHistoryKey);
    if (!value) {
        return false;
    } else {
        const list = parseJson(value);
        const newList = list.map((rec: HashRecord) => {
            if (rec.hash === hash) {
                rec.status = status;
            }
            return rec;
        });
        localStorage.setItem(hashHistoryKey, JSON.stringify(newList));
    }
}

// Function to update processing transactions
export function updateProcessingTransactions() {
    console.log("updateProcessingTransactions");
    const value = localStorage.getItem(hashHistoryKey);
    if (!value) {
        return false;
    } else {
        const list = parseJson(value);
        const newList = list.map((rec: HashRecord) => {
            if (rec.status === "Processing") {
                rec.show = true;
            }
            return rec;
        });
        localStorage.setItem(hashHistoryKey, JSON.stringify(newList));
    }
}

/**
 * Function to convert an error object to a string message.
 * @param error - The error object or message to be converted.
 * @returns A formatted error message.
 */
export function stringifyError(error: unknown): string {
    if (error && typeof error === "object" && "message" in error) {
        // Handle special JSON-RPC errors
        const message = String(error.message);
        const rpcMatch = message.match(/^Internal JSON-RPC error\.\n(\{[\s\S]+\})/);
        if (rpcMatch) {
            try {
                const parsed = JSON.parse(rpcMatch[1]);
                if (parsed.message) {
                    return parsed.message;
                }
            } catch (parseError: unknown) {}
        }
        return message;
    } else {
        return String(error);
    }
}

/**
 * Function to format a meaningful decimal value.
 * @param price - The price value to be formatted.
 * @param meaningfulDecimals - The number of meaningful decimals.
 * @returns The formatted decimal value.
 */
export function formatMeaningfulDecimalValue(price: string, meaningfulDecimals = 4): string {
    // Calculate decimals for formatting
    let decimals = 0;
    const pos = price.toString().replace(".", "").toString().search(/[^0.]/);
    const pointPosition = price.toString().search(/[.]/);
    if (pointPosition !== -1 && pointPosition < pos + meaningfulDecimals) {
        decimals = pos - pointPosition + meaningfulDecimals;
    }

    return new BigNumber(price).toFixed(decimals); // Format the value with the calculated decimals
}

/**
 * Function to extract the base name from a URL.
 * @param str - The URL string.
 * @returns The extracted base name.
 */
export function baseName(str: string | undefined) {
    if (!str) {
        return str;
    }
    let base = str.substring(str.lastIndexOf("/") + 1);
    if (base.lastIndexOf(".") != -1) {
        base = base.substring(0, base.lastIndexOf("."));
    }
    return base;
}


export function formattedTodayDate() {
    const date = new Date();
    return date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getDate();
}

export function formattedLastMonthDate() {
    const date = new Date(+new Date() - 30 * 86400 * 1000);
    return date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate();
}

export function formatNumber(num: number | string, delim = ",") {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + delim);
}