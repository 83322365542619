import type { BlockchainConfig } from "./blockchain/ContractAddresses";

class Config {
    blockchain: BlockchainConfig;
    api: string;

    // 56: "Binance Smart Chain Mainnet",
    // 97: "Binance Smart Chain Testnet",

    constructor(config?: {
        pearl: string;
        yield: string;
        usdt: string;
        store: string;
        pair: string;
        marketing: string;
    }) {
        this.blockchain = {
            blockScanUrl: "https://testnet.bscscan.com",
            networkId: 97,
            nodeUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
            maxGasPerTx: 4,
            minConfirmationsCount: 8,
            contracts: {
                pearl: "0x2E55f08E539AA5a980dC645A0eB13e78E6B61B35",
                pearlStore: "0x7dfAA694b720AA9a9e1744C9Be82d8EA9d60Be0f",
                pearlYield: "0x90982c16f06CC94A96A22C50CD424A38AF49e68D",
                USDT: "0xab1a4d4f1d656d2450692d237fdd6c7f9146e814",
                treasury: "0x96C4f470c18f2D1215D7C6c68afA13570D2C81Be",
            }
        };
        this.api = "https://pearl-api.techcuties.com";
    }
}

//const res = await PearlApiService.config("https://pearl-api.techcuties.com");
// console.log(res);

// return singleton
export const ConfigInstance = new Config();
